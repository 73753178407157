import { instance } from '@/utils/http.js'

// 年级获取模式
export const gradeGetMode = function (params) {
  return instance({
    url: '/api/v2/public/grade_mode',
    method: 'get',
    params
  })
}

