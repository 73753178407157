<template>
  <div>
    <el-dialog title="查看学生"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">

      <el-table border
                :stripe="true"
                highlight-current-row
                :data="arr"
                style="width: 100%">
        <el-table-column prop="grade_name"
                         align="center"
                         label="年级" />
        <el-table-column prop="subject_name"
                         align="center"
                         label="科目" />
        <el-table-column prop="truename"
                         align="center"
                         label="真实姓名" />
        <el-table-column prop="verifiedMobile"
                         align="center"
                         label="手机号" />

      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        grade_id: '',
      },
      special_class_id: '',
      page: 1,
      size: 10,
      totalElements: 0,
      dialogVisible: false,
      gradeList: [],
      tableData: [],
      arr: [],
    }
  },
  mounted () {
  },
  methods: {
    handleClose () {
      this.form = {
        grade_id: '',
        class_id: '',
      }
      this.arr = []
      this.tableData = []
      this.dialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.btn {
  text-align: center;
  margin-top: 40px;
}
</style>
