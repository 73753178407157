<template>
  <div>
    <el-form :model="form"
             label-width="60px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="学校"
                        prop="school_id">
            <el-select v-model="form.school_id"
                       clearable
                       filterable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="名称">
            <el-input clearable
                      style="width:100%"
                      placeholder="班级名称关键字"
                      v-model="form.keyword"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="届">
            <el-select v-model="form.graduated"
                       clearable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in jieList"
                         :key="item.graduated_id"
                         :label="item.year_desc"
                         :value="item.graduated_id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10"
              style="margin-bottom:20px">
        <el-col :span="24">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="addClass">新增</el-button>
          <el-button type="primary"
                     @click="queckCreate">一键创班</el-button>
          <el-button type="primary"
                     style="width:112px"
                     @click="importClass">导入教学班级</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <LookStudent ref="CheckStudentRef" />
    <QuickCreate ref="QuickCreaterRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import LookStudent from './components/lookStudent.vue'
import QuickCreate from './components/quickCreate.vue'
import { getStartSchool } from '@/api/school.js'

export default {
  data () {
    return {
      form: {
        keyword: '',
        graduated: '',
        school_id: ''
      },
      statusList: [
        { label: '开启', value: 1 },
        { label: '关闭', value: 0 },
      ],
      jieList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'cz_classes_class_admin',
      tableColumns: [
        { prop: 'id', align: 'center', label: '编号', width: '80', showToopic: false },
        { prop: 'year_desc', align: 'center', label: '届', width: '', showToopic: false },
        { prop: 'class_name', align: 'center', label: '班级名称', width: '', showToopic: false },
        { prop: 'create_time', align: 'center', label: '添加时间', width: '', showToopic: false },
        { prop: 'end_time', align: 'center', label: '过期时间', width: '', showToopic: false },
        { prop: 'status', align: 'center', label: '状态', width: '', showToopic: false, isStatus: true },
        { prop: 'school_name', align: 'center', label: '学校名称', width: '', },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '添加教师', type: 'teacher', event: 'addTeacher' },
            { content: '查看学生', type: 'lookStudent', event: 'lookStudent' },
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      schoolList: [],
      gradeList: [],
    }
  },
  components: {
    Form, LookStudent, QuickCreate
  },
  mounted () {
    this.setTablesColums()
    this.getSchoolList()
    this.getJieList()
  },
  methods: {
    async getSchoolList () {
      const { data } = await getStartSchool()
      this.schoolList = data.list
    },
    getClass (prop, value) {
      if (prop == 'status') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '开启' : '关闭'
      }
    },
    lookStudent (row) {
      this.$http({
        url: '/api/v2/special/show_student',
        method: 'get',
        params: {
          special_class_id: row.id
        }
      }).then(res => {
        this.$refs.CheckStudentRef.arr = res.data
        this.$refs.CheckStudentRef.dialogVisible = true
      })

    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.graduated_id = form.graduated
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/special/class_lst',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    editRow (row) {
      let form = {
        id: row.id,
        graduation_id: row.graduated_id,
        type: row.type,
        class_name: row.class_name,
        end_time: row.end_time,
        status: row.status,
        app_id: row.app_id,
        school_id: row.school_id
      }

      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getList()
      this.$refs.FormRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/special/class_del',
          method: 'post',
          data: {
            id: row.id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addClass () {
      let form = {
        class_name: '',
        graduation_id: '',
        type: 1,
        app_id: 1,
        mode_id: '',
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.text = '新增'
      let is_retail = JSON.parse(window.localStorage.getItem('userInfo')).is_retail
      this.$refs.FormRef.is_retail = is_retail
      this.$refs.FormRef.getList()
      this.$refs.FormRef.getAppList()
      this.$refs.FormRef.dialogVisible = true
    },
    queckCreate () {
      let form = {
        class_name: '',
        graduation_id: '',
        type: 1,
        app_id: 1,
        mode_id: '',
        school_id: '',
        grade_id: '',
        mode_id: '',
      }
      this.$refs.QuickCreaterRef.form = form
      this.$refs.QuickCreaterRef.getList()
      this.$refs.QuickCreaterRef.dialogVisible = true
    },
    // 导入教学班级
    importClass () {
      let file;
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }).then(res => {
        file = res
        let formData = new FormData()
        // let form = JSON.parse(JSON.stringify(this.form))
        // for (const key in form) {
        //   formData.append(key, this.form[key])
        // }
        formData.append('file', file)
        formData.append('sign', this.$getMd5Form(file))
        this.$http({
          url: '/api/v2/special/import_class',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.search()
        })
      })

    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    addTeacher (row) {
      this.$router.push('/classes/teacher?special_class_id=' + row.id)
    },
    getJieList () {
      this.$http({
        url: '/api/v2/public/graduated_lst',
        method: 'get'
      }).then(res => {
        this.jieList = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
