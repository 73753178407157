import { instance } from '@/utils/http.js'

// 获取年级
export const getGrade = function () {
  return instance({
    url: '/api/v2/public/grade_lst',
    method: 'get'
  })
}

